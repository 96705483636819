<template>
  <div class="mobile-container">
    <mobileStatusBar title="密钥管理" />
    <div class="column-item">
      <div class="title-item">AppId：</div>
      <div class="detail-item">
        <div class="value">{{ userInfo.AppId }}</div>
        <div class="btn public-btn" @click="handleCopy(userInfo.AppId)">复制</div>
      </div>
    </div>
    <div class="column-item">
      <div class="title-item">AppSecret：</div>
      <div class="detail-item">
        <div class="value">{{ userInfo.AppSecret }}</div>
        <div class="btn public-btn" @click="handleCopy(userInfo.AppSecret)">复制</div>
      </div>
    </div>
    <div class="flex-1"></div>
    <div class="submit-btn public-btn" @click="showResetPopup = true">重置密钥</div>

    <van-popup v-model="showResetPopup" position="center" style="border-radius: .16rem;">
      <div class="mobile--reset-popup-container">
        <div class="title">提示</div>
        <div class="detail">确定要重置密钥吗？</div>
        <div class="btn-item">
          <div class="btn" @click="showResetPopup = false">取消</div>
          <div class="btn sure" @click="handleSubmit">
            <van-loading v-if="submiting" color="#ffffff" />
            <span v-else>确定</span>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import mobileStatusBar from '../../components/mobileStatusBar.vue'

export default {
  name: "Secret",
  components: {
    mobileStatusBar
  },
  data() {
    return {
      showResetPopup: false,
      submiting: false
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  watch: {
    
  },
  mounted() {

  },
  methods: {
    ...mapActions(['GetUserInfo']),
    handleCopy(text) {
      this.$copyText(`${text}`).then(() => {
        this.$toast('复制成功')
      }).catch(() => {
        this.$toast('复制失败')
      })
    },
    async handleSubmit() {
      const { userInfo } = this
      this.submiting = true
      var res = await this.$API.resetKey.post({
        AppId: userInfo.AppId,
        AccessKeyId: userInfo.AppSecret,
      })
      this.submiting = false
      if(res.error == 0){
        this.$toast.success(res.info)
        this.GetUserInfo()
      } else {
        this.$dialog.alert({
          type: 'danger',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定',
        })
        return false
      }
      this.showResetPopup = false
    }
  },
};
</script>

<style lang="less" scoped>
.mobile-container {
  width: 100%;
  min-height: 100vh;
  background-color: #f8f8f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.column-item {
  padding: .32rem;
  font-size: .28rem;
  color: #242941;
  &:nth-child(1) {
    padding-top: .6rem;
  }
  .title-item {
    line-height: .4rem;
  }
  .detail-item {
    margin-top: .2rem;
    width: 6.86rem;
    height: .88rem;
    background-color: #fff;
    border-radius: .16rem;
    box-sizing: border-box;
    padding: 0 .08rem 0 .24rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .value {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .btn {
      cursor: pointer;
      margin-left: .3rem;
      width: .96rem;
      height: .72rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: .12rem;
      color: #fff;
      font-weight: 500;
      font-size: .24rem;
    }
  }
}
.submit-btn {
  cursor: pointer;
  width: 6.86rem;
  height: .88rem;
  border-radius: .12rem;
  color: #fff;
  font-size: .3rem;
  font-weight: bold;
  margin-bottom: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile--reset-popup-container {
  width: 5rem;
  padding: .4rem;
  text-align: center;
  .title {
    line-height: .48rem;
    color: #11113a;
    font-size: .32rem;
    font-weight: bold;
  }
  .detail {
    margin-top: .28rem;
    color: #bcbdc5;
    line-height: .4rem;
    font-size: .28rem;
  }
  .btn-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: .72rem;
    .btn {
      cursor: pointer;
      width: 2.4rem;
      height: .8rem;
      border-radius: .12rem;
      background-color: #bac0cc;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-weight: bold;
      font-size: .24rem;
      &.sure {
        background-color: #1399ff;
      }
    }
  }
}
</style>